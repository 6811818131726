<template>
    <div class="page">
        <div class="card">
            <h2>Characters</h2>
        </div>
        <div class="card character" :id="c.name" v-for="c in characters" :key="c.name">
            <img :src="c.image">
            <h2>{{c.name}}</h2>
            <h3 v-for="n in c.alt_name" :key="n">{{n}}</h3>
            <table v-for="t in c.tables.filter(t=>t.type===1)" :key="t.type + Math.random()*100">
                <tr v-for="(row) in t.rows" :key="row[0]">
                    <th>{{row[0]}}</th>
                    <td v-for="r in row.filter((z,i)=>i>0)" :key="r">{{r}}</td>
                </tr>
            </table>
            <a v-for="u in c.source" :key="u" :href="u">Source</a>
        </div>
    </div>
</template>
<script>
import characters from '@/data/character-manifest.json'

export default {
    name: 'characters',
    components: {},
    data: function () {
        return {
            characters: characters
        }
    }
}
</script>